import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	tab: 'introduction',
	tabTempSaveCheck: false,
	reportId: 0,
	pageType: '',
	energePageType: 'register',
	introDetailPageType: 'register'
}

const machineReport = createSlice({
	name: 'machineReport',
	initialState,
	reducers: {
		setTab: (state, action) => ({
			...state,
			['tab']: action.payload
		}),
		setTabTempSaveCheck: (state, action) => ({
			...state,
			['tabTempSaveCheck']: action.payload
		}),
		setReportId: (state, action) => ({
			...state,
			['reportId']: action.payload
		}),
		setPageType: (state, action) => ({
			...state,
			['pageType']: action.payload
		}),
		setEnergePageType: (state, action) => ({
			...state,
			['energePageType']: action.payload
		}),
		setIntroDetailPageType: (state, action) => ({
			...state,
			['introDetailPageType']: action.payload
		})
	}
})

export const {
	setTab,
	setTabTempSaveCheck,
	setReportId,
	setPageType,
	setEnergePageType,
	setIntroDetailPageType
} = machineReport.actions

export default machineReport.reducer